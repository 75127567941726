import React, { useState } from "react";
import PropTypes from 'prop-types';

import * as Styled from './styles';

import { Modal, Button } from 'antd';
import {
  FilePdfOutlined,
  NumberOutlined,
  FileTextOutlined
} from '@ant-design/icons';



const PubList = ({ tag, author, title, year, cite, doi }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <>
    <Styled.Timeline>
      <Styled.Point />

      <Styled.Details>
        <Styled.Date>
          {year}
        </Styled.Date>
        <Styled.Tag>{tag}</Styled.Tag>
      </Styled.Details>

      <Styled.Content>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{author}</Styled.Subtitle>
      </Styled.Content>

      <Styled.PubLinks>
        <div className="icons-list">
          <Button danger type="text" href={'https://doi.org/' + doi} icon={ <NumberOutlined style={{ fontSize: '15px', color: '#08c' }}/>}>doi</Button> <br />
          <Button danger onClick={showModal} type="text" icon={ <FileTextOutlined style={{ fontSize: '15px', color: '#08c' }}/>}>cite</Button> <br />
          <Button danger type="text" icon={ <FilePdfOutlined style={{ fontSize: '15px', color: '#08c' }}/>}>pdf</Button> <br />
          <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <p>{cite}</p>
          </Modal>
        </div>

      </Styled.PubLinks>
    </Styled.Timeline>
    </>
  );
};

PubList.propTypes = {
  tag: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  cite: PropTypes.string.isRequired,
  doi: PropTypes.string,
};

export default PubList;