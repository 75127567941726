import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';

import Publications from 'components/Publications';

const PublicationPage: React.FC = () => (
  <>
  <Layout>
    <SEO title="Publications" />
    <Publications />
    <hr />
    {/*<Education />*/}
    <hr />
    {/*<Skills />*/}
  </Layout>
  </>
);

export default PublicationPage;
