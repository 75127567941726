import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import PubList from 'components/ui/PubList';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

const Publications = () => {
  const data = useStaticQuery(graphql`
    {
      allReference(sort: {fields: year, order: DESC}) {
        edges {
          node {
            id
            title
            author
            year
            mendeleytags
            raw
            doi
          }
        }
      }
    }
  `);

  const pubs = data.allReference.edges;


    return (
    <Container section>
      <TitleSection title="My Publications" subtitle="Full updated list on Google Scholar" />

      {pubs.map((item) => {
        const {
          id,
          title,
          author,
          year,
          mendeleytags,
          raw,
          doi
        } = item.node;

        return (
          <PubList
            key={id}
            tag={mendeleytags}
            author={author}
            title={<FormatHtml content={title} />}
            year={year}
            cite={raw}
            doi= {doi}
          />
        );
      })}
    </Container>
  );
};

export default Publications;
